import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  public drugSearchElement$ = new BehaviorSubject<boolean>(true);
  public drugSearchItem$ = this.drugSearchElement$.asObservable();

  public globalLoadingElement$ = new BehaviorSubject<boolean>(false);
  public globalLoadingItem$ = this.globalLoadingElement$.asObservable();

  constructor() {}

  /**
   * Sets "drug search" to hide and show formulary display  
   * @param value
   */
  SetDrugSearch(value: boolean){
    this.drugSearchElement$.next(value);
  }

  /**
   * Sets and unsets global loading value 
   * @param value 
   */
  SetGlobalLoading(value: boolean){
    this.globalLoadingElement$.next(value);
  }
  
}
