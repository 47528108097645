<div class="search-wrapper">
    <div class="welcome-wrapper" *ngIf="showSearch">
      <div class="welcome-container">
        <div class="welcome-message" [innerHtml]="welcomeMessage | safeHtml"></div>
      </div>
    </div>
    <div class="search-container primary-background">
        <form class="search-form" (ngSubmit)="GetFormularyInfo(drugSearch.value)" (keydown.enter)="IsSubmitButtonDisabled(drugSearch.value) ? $event.preventDefault() : true">
            <mat-form-field class="search-full-width">
                <mat-icon class="search-button" matPrefix >search</mat-icon>
                <input type="text"
                       placeholder="Find a Drug"
                       aria-label="Find a Drug"
                       matInput
                       (paste)="CheckPasteEvent()"
                       [formControl]="drugSearch"
                       [matAutocomplete]="auto" >
                <mat-spinner matSuffix *ngIf="drugSearchLoading" diameter="20"></mat-spinner>
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let option of foundDrugList | filterDrugList: drugSearch.value" [value]="option.DrugName">
                    {{option.DrugName}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <div class="search-button-container">
                <button mat-raised-button color="primary" type="submit" [disabled]="IsSubmitButtonDisabled(drugSearch.value)" >Search</button>
            </div>
        </form>
    </div>
    <div class="drug-search-warning" *ngIf="showDrugSearchError">
        <span class="warn-color" >{{drugSearchErrorMessage}}</span>
     </div>
</div>
