import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/services/global.service';
import { DrugInfo } from 'src/model/drug-info.model';
import { TempDrugInfo } from 'src/model/temp-drug-info.model'
import { Observable, Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { FormularyLookupService } from 'src/services/formulary-lookup.service';
import { FormularySearchResponse } from 'src/model/response/formulary-search-response.model';
import { BaseResponse } from 'src/model/response/base-response.model';
import { DrugInfoResponse } from 'src/model/response/drug-info-response.model';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { AnalyticsService } from 'src/services/analytics.service';
import { NotCoveredIndexes } from 'src/model/not-covered-indexes.model'

@Component({
  selector: 'app-formulary-display',
  templateUrl: './formulary-display.component.html',
  styleUrls: ['./formulary-display.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0'})),
      state('expanded', style({ height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class FormularyDisplayComponent implements OnInit {

  foundFormularyDataSub!: Subscription;
  foundFormularyData!: BaseResponse<Array<FormularySearchResponse>>;

  columnsToDisplay: Array<string> = ["DrugName","Strength","DosageForm","TherapyClass","Tier","Limits/Restrictions"];
  columnsToDisplayNone: Array<string> = ["DrugName","NoInformation"];

  constructor(private _globalService: GlobalService,
              private _formularyService: FormularyLookupService,
              private _analyticsService: AnalyticsService) {}


  ngOnInit(): void {
    this.foundFormularyDataSub = this._formularyService.formularyDrugInfoItem$.subscribe(result =>{
      if(result){
        let scrubbedResult = this.RemoveAllNotCoveredAlternatives(result.Data);
        this.foundFormularyData = result;
        this.foundFormularyData.Data = scrubbedResult;
        this._globalService.SetGlobalLoading(false);
      }
    });
  }

  /**
   * On Tab change we send event data to Google Analytics and 
   * check view height to fix any column wrapping
   * @param event 
   */
  TabChangeEvent(event: MatTabChangeEvent){
    this._analyticsService.emitEvent("click", `Moved to tab ${event.index + 1}: ${this.foundFormularyData.Data[event.index].FormularyName}` );
  }

  /**
   * Predicate that decides which column to show in view.
   * If there is data we show data row (columnsToDisplay)
   * @param index 
   * @param data 
   */
  CheckRowHasData(index: number, data: DrugInfoResponse){
    return data.ExtraItemsCount > 0;
  }

  /**
   * Predicate that decides which column to show in view.
   * If there is NO data we show no data row (columnsToDisplayNone)
   * @param index 
   * @param data 
   */
  CheckRowHasNoData(index: number, data: DrugInfoResponse){
    return data.ExtraItemsCount == 0;
  }

  /**
   * Probes data to find and remove any drugs that have been marked as Not Covered("NC")
   * this occurs of inside of Alternatives object only.
   * @param data 
   * @returns 
   */
  RemoveAllNotCoveredAlternatives(data: Array<FormularySearchResponse>): Array<FormularySearchResponse> {
    let fullDeleteList = new Array<number>();
    data.forEach((dataSet) => {
      if(dataSet.AlternativeDrugInfo.length > 0){
        let indexList = this.DetermineAlternativeNotCoveredIndex(dataSet);
        for(var index = 0; index < indexList.length; index++){
          // remove NotesPublic entry
          dataSet.AlternativeDrugInfo[indexList[index].DrugIndex].NotesPublic.splice((indexList[index].NotesPublicIndex - index), 1);
          // remove Dose entry
          dataSet.AlternativeDrugInfo[indexList[index].DrugIndex].Dose.splice((indexList[index].NotesPublicIndex - index), 1);
          // remove MaxorClass entry
          dataSet.AlternativeDrugInfo[indexList[index].DrugIndex].MaxorClass.splice((indexList[index].NotesPublicIndex - index), 1);
          // remove Strength entry
          dataSet.AlternativeDrugInfo[indexList[index].DrugIndex].Strength.splice((indexList[index].NotesPublicIndex - index), 1);
          // remove Tier entry
          dataSet.AlternativeDrugInfo[indexList[index].DrugIndex].Tier.splice((indexList[index].NotesPublicIndex - index), 1);
          dataSet.AlternativeDrugInfo[indexList[index].DrugIndex].ExtraItemsCount--;
          if(dataSet.AlternativeDrugInfo[indexList[index].DrugIndex].ExtraItemsCount == 0){
            fullDeleteList.push(indexList[index].DrugIndex);
            console.log("we need to delete "+ dataSet.AlternativeDrugInfo[indexList[index].DrugIndex].DrugName);
          }
        }
        for(var x = 0; x < fullDeleteList.length; x++){
          dataSet.AlternativeDrugInfo.splice((fullDeleteList[x] - x), 1);
        }
      }
    });
    
    return data;
  }

  /**
   * Finds Not Covered("NC") Alternatives and returns list of indexes that you need to remove
   * @param dataSet 
   * @returns 
   */
  DetermineAlternativeNotCoveredIndex(dataSet: FormularySearchResponse): Array<NotCoveredIndexes>{
    let indexList = Array<NotCoveredIndexes>();
    for(var x = 0; x < dataSet.AlternativeDrugInfo.length; x ++){
      if(dataSet.AlternativeDrugInfo[x].NotesPublic){
        for(var y = 0; y < dataSet.AlternativeDrugInfo[x].NotesPublic.length; y++){
          for(var z = 0; z < dataSet.AlternativeDrugInfo[x].NotesPublic[y].length; z++){
            if(dataSet.AlternativeDrugInfo[x].NotesPublic[y][z].DisplayName == "NC"){
              let temp = new NotCoveredIndexes();
              temp.DrugIndex = x;
              temp.NotesPublicIndex = y;
              indexList.push(temp);
            }
          }
        }
      }
    }
    return indexList;
  }

}
