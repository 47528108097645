
<div class="header-wrapper">
    <div class="header-container">
        <div class="logo-wrapper">
            <div class="logo-container">
                <img class="logo" src="{{config.LogoPath}}">
            </div>
        </div>
        <div class="app-tag-wrapper">
            <div class="app-tag-container">
                <h2 class="mat-primary">Drug Search</h2>
            </div>
        </div>
    </div>
  </div>