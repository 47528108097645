<mat-table [dataSource]="dataSource" class="mat-elevation-z2 main-drug-table" multiTemplateDataRows cdkScrollable>
    <ng-container matColumnDef="DrugName">
        <mat-header-cell *matHeaderCellDef> Drug Name </mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="Drug Name"> 
            <p class="cell-extended"
                matTooltipPosition="above"
               matTooltipClass="tooltip-solid"
               [overflowTooltip]="element.DrugName"
               >{{element.DrugName || "&nbsp;"}}</p> 
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="Strength">
        <mat-header-cell *matHeaderCellDef> Strength </mat-header-cell>
        <mat-cell [ngClass]="{'is-multi-row': element.ExtraItemsCount > 1,'align-single-row': element.ExtraItemsCount <= 1}" *matCellDef="let element;" data-label="Strength">
            <p mat-cell
               class="cell-extended" 
               matTooltipPosition="above"
               matTooltipClass="tooltip-solid"
               [matTooltip]="strength.Tooltip"
               *ngFor="let strength of element.Strength;">
                {{strength.DisplayName || "&nbsp;"}}
            </p>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="DosageForm">
        <mat-header-cell *matHeaderCellDef> Dosage Form </mat-header-cell>
        <mat-cell [ngClass]="{'is-multi-row': element.ExtraItemsCount > 1,'align-single-row': element.ExtraItemsCount <= 1}" *matCellDef="let element;" data-label="Dosage Form">
            <p mat-cell 
               class="cell-extended" 
               matTooltipPosition="above"
               matTooltipClass="tooltip-solid"
               [overflowTooltip]="dosage.DisplayName"
               *ngFor="let dosage of element.Dose;">
                {{dosage.DisplayName || "&nbsp;"}}
            </p>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="TherapyClass">
        <mat-header-cell *matHeaderCellDef> Therapy Class </mat-header-cell>
        <mat-cell [ngClass]="{'is-multi-row': element.ExtraItemsCount > 1,'align-single-row': element.ExtraItemsCount <= 1}" *matCellDef="let element;" data-label="Therapy Class">
            <p mat-cell 
               class="cell-extended" 
               matTooltipPosition="above"
               matTooltipClass="tooltip-solid"
               [matTooltip]="class.Tooltip"
               *ngFor="let class of element.MaxorClass;">
                {{class.DisplayName || "&nbsp;"}}
            </p>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="Tier">
        <mat-header-cell *matHeaderCellDef> Tier </mat-header-cell>
        <mat-cell [ngClass]="{'is-multi-row': element.ExtraItemsCount > 1,'align-single-row': element.ExtraItemsCount <= 1}" *matCellDef="let element;" data-label="Tier">
            <p mat-cell 
               class="cell-extended" 
               matTooltipPosition="above"
               matTooltipClass="tooltip-solid"
               [matTooltip]="tier.Tooltip"
               *ngFor="let tier of element.Tier;">
                {{tier.DisplayName || "&nbsp;"}}
            </p>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="Limits/Restrictions">
        <mat-header-cell *matHeaderCellDef> Limits/Restrictions </mat-header-cell>
        <mat-cell [ngClass]="{'is-multi-row': element.ExtraItemsCount > 1,'align-single-row': element.ExtraItemsCount <= 1}" *matCellDef="let element;" data-label="Limits/Restrictions">
            <div mat-cell class="cell-extended" *ngFor="let notes of element.NotesPublic;">
                <span matTooltipPosition="above"
                   matTooltipClass="tooltip-solid"
                   [matTooltip]="note.Tooltip"
                   *ngFor="let note of notes">
                    {{note.DisplayName || "&nbsp;"}}
                </span>
            </div>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="NoInformation">
        <mat-cell *matCellDef="let element">
            <p mat-cell class="cell-extended">No additional information found</p>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="expandedDetail">
        <mat-cell *matCellDef="let element">
            Tier 2 medication, typically preferred or formulary brand drugs.<br>
            Quantity limits may apply
        </mat-cell>
    </ng-container>
</mat-table>
