import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { MaxorHttpInterceptor } from './maxor-http.interceptor';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

// MATERIAL IMPORTS
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatChipsModule} from '@angular/material/chips';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTableModule} from '@angular/material/table';
import {MatCardModule} from '@angular/material/card';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import { DrugSearchComponent } from './drug-search/drug-search.component';
import { FormularyDisplayComponent } from './formulary-display/formulary-display.component';
import { HeaderComponent } from './header/header.component';
import { ConfigService } from 'src/services/config.service';
import { TermsOfUseModalComponent } from './terms-of-use-modal/terms-of-use-modal.component';
import { FormularyTableComponent } from './formulary-table/formulary-table.component';
import { FilterDrugListPipe } from '../pipes/filter-drug-list.pipe';
import { SafeHtmlPipe } from '../pipes/safe-html.pipe';
import { TooltipDirective } from '../directives/tooltip.directive';

const appInitializerFn = (appConfig: ConfigService) => {
  return () => {
    return appConfig.InitConfigPromise();
  };
};
@NgModule({
  declarations: [
    AppComponent,
    DrugSearchComponent,
    FormularyDisplayComponent,
    HeaderComponent,
    TermsOfUseModalComponent,
    FormularyTableComponent,
    FilterDrugListPipe,
    SafeHtmlPipe,
    TooltipDirective,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatChipsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatTabsModule,
    MatTooltipModule,
    MatDialogModule,
    MatTableModule,
    MatCardModule,
    MatSnackBarModule,
    MatProgressSpinnerModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MaxorHttpInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [ConfigService],
    }
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
