import { Component, OnDestroy, Renderer2, OnInit } from '@angular/core';
import { ConfigService } from 'src/services/config.service';
import { Config } from 'src/model/config.model';
import { AnalyticsService } from 'src/services/analytics.service';
import { GlobalService } from 'src/services/global.service';
import { MatDialog } from '@angular/material/dialog';
import { TermsOfUseModalComponent } from 'src/app/terms-of-use-modal/terms-of-use-modal.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  
  config!: Config;
  globalLoading: boolean = false;
  
  // disclaimer stuffs
  disclaimer!: string
  welcomeMessage!: string;
  showSearch: boolean = true;

  constructor(private _configService: ConfigService, 
              private _renderer: Renderer2,
              private _analyticsService: AnalyticsService,
              private _globalService: GlobalService,
              public dialog: MatDialog){}

  ngOnInit() {
    this.InitializeApplication();
    this._globalService.drugSearchItem$.subscribe(value =>{
      // showSearch shows and hides the formulary results.
      this.showSearch = value;
      if(value == false){
        let style = document.getElementById('disclaimer')?.style;
        if(style){
          style.position = "relative";
          style.width = "98%";
        }
      }
    });
    // subscribe to global loading variable
    this._globalService.globalLoadingItem$.subscribe(value =>{
      this.globalLoading = value;
    });
  }

  /**
   * Initializes application on startup sets configs and styles.
   */
  InitializeApplication(){
    let interval = setInterval(() =>{
      this.config = this._configService.GetConfig();
      if(this.config.Theme){
        this._renderer.addClass(document.body, this.config.Theme);
        this._analyticsService.init();
        this._configService.SetFavicon(this.config.FaviconPath);
        //this.disclaimer = `${this.config.ClientName} FORMULARY TOOL is provided to the user(s) \"AS IS.\" WE : (A) CANNOT AND DO NOT WARRANT THE SEQUENCE, ACCURACY, COMPLETENESS, CURRENCY, RESULTS OBTAINED FROM, OR NON-INFRINGEMENT OF THE ${this.config.ClientName} FORMULARY TOOL PROVIDED HEREUNDER; AND (B) EXPRESSLY DISCLAIM ALL WARRANTIES AND CONDITIONS, EXPRESS, IMPLIED OR STATUTORY, INCLUDING WITHOUT LIMITATION THE IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. The laws of some jurisdictions do not permit waivers of certain warranties, so portions of the above disclaimer may not apply to User(s). In the event we cannot waive any warranty, the duration and scope of such warranty will be the minimum permitted under applicable law. ANY MATERIAL OR DATA OBTAINED THROUGH USE OF THE ${this.config.ClientName} FORMULARY TOOL IS AT USER'S OWN DISCRETION AND RISK AND USER UNDERSTANDS THAT IT WILL BE SOLELY RESPONSIBLE FOR ANY RESULTING DAMAGE, INCLUDING TO USERS.`;
        clearInterval(interval);
      }
    }, 100);
  }

  /**
   * Opens terms of use modal. Sends event to Google Analytics.
   */
  OpenTermsOfUse() {
    this._analyticsService.emitEvent('click', 'Opened Terms of Use.');
    const dialogRef = this.dialog.open(TermsOfUseModalComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

}
