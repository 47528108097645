import { Component, Host, OnInit } from '@angular/core';
import { GlobalService } from 'src/services/global.service';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { startWith, map, switchMap, debounceTime, finalize, tap, mergeMap } from 'rxjs/operators';
import { Config } from 'src/model/config.model';
import { ConfigService } from 'src/services/config.service';
import { DrugSearchResponse } from '../../model/response/drug-search-response.class';
import { FormularyLookupService } from '../../services/formulary-lookup.service';
import { DrugSearchRequest } from 'src/model/request/drug-search-request.model';
import { BaseResponse } from 'src/model/response/base-response.model';
import { ResponseStatusCodes } from 'src/model/response/response-status-codes.enum';
import { FormularySearchRequest } from 'src/model/request/formulary-search-request.model';
import { AnalyticsService } from 'src/services/analytics.service';

@Component({
  selector: 'app-drug-search',
  templateUrl: './drug-search.component.html',
  styleUrls: ['./drug-search.component.scss']
})
export class DrugSearchComponent implements OnInit {

  // _mainPage!: AppComponent;

  welcomeMessage!: string;
  config!: Config;
  
  // Form Control info
  drugSearch = new FormControl();
  foundDrugList: Array<DrugSearchResponse> = new Array<DrugSearchResponse>();
  showSearch: boolean = true;
  drugSearchLoading: boolean = false;
  currentDrugSearchValue: string = "";
  showDrugSearchError: boolean = false;
  drugSearchErrorMessage: string = "";
  hasPaste: boolean = false;

  constructor(private _globalService: GlobalService,
              private _configService: ConfigService,
              private _analyticsService: AnalyticsService,
              private _formularyService: FormularyLookupService,
              ) {
  }

  ngOnInit(): void {
    this._globalService.drugSearchItem$.subscribe(value =>{
      this.showSearch = value;
    })
    this.config = this._configService.GetConfig();
    this.welcomeMessage = `<p style="font-weight: 700">Welcome to the Drug Search Tool!</p><p>Learn more about our prescription medication coverage, including those with additional requirements such as prior authorization or quantity limits by searching with the drug name. There are coverage limitations and possible exclusions which may mean that certain drugs are not covered by our Plan. Covered and preferred drugs must be filled at a network pharmacy.</p>`;
    // With this on value change event we will handle drug search request. 
    this.drugSearch.valueChanges.pipe(
        tap(() =>{
         
        }),
        mergeMap((value: string) => 
        {
          if(this.hasPaste){
            value = value.substring(0, 3);
            this.hasPaste = false;
          }
          if(value.length == 0){
            this.showDrugSearchError = false;
          }
          if(value.length < 3){ 
            return of(null);
          }
          if(value.length == 3 && value != this.currentDrugSearchValue){
            this.drugSearchLoading = true;
            this.showDrugSearchError = false;
            this.currentDrugSearchValue = value;
            let payload = new DrugSearchRequest();
            payload.DrugName = value;
            payload.WebRegionId = this.config.WebRegionId;
            this._analyticsService.emitEvent('submit', 'Search Drug');
            return this._formularyService.SearchDrug(payload).pipe(
              finalize(() =>{
                this.drugSearchLoading = false;
              }),
            )
          }
          else{
            return of(null);
          }
        })
    ).subscribe(result =>{
      if(result == null) return;
      if(result.StatusCode == ResponseStatusCodes.SUCCESS){
        this.foundDrugList = result.Data;
        this.drugSearchLoading = false;
      }
      else if(result.StatusCode == ResponseStatusCodes.NO_DRUGS_FOUND){
        this.drugSearchErrorMessage = "No Drug Found, Please Try Another Search";
        this.showDrugSearchError = true;
        this.drugSearchLoading = false;
      }
      else{
        this.drugSearchErrorMessage = "There was a problem getting your drug results, please try again.";
        this.showDrugSearchError = true; 
        this.drugSearchLoading = false;
      }
    })
  }

  /**
   * Gets formulary info based on searched drug passed in by user.
   * @param searchedDrug 
   */
  GetFormularyInfo(searchedDrug: string){
    this._analyticsService.emitEvent('submit', `Attempting to get Formulary Info for ${searchedDrug}`);
    this.showDrugSearchError = false;
    // if drug doesn't exist in list we return Error. 
    if(!this.foundDrugList.some(value => value.DrugName == searchedDrug)){
      this.drugSearchErrorMessage = "Please select a drug from the drop down and try again."
      this.showDrugSearchError = true;
      return;
    }
    this._globalService.SetGlobalLoading(true);
    let payload = new FormularySearchRequest();
    payload.DrugName = searchedDrug;
    payload.WebRegionId = this.config.WebRegionId;
    this._formularyService.GetFormularyDrugInfo(payload).subscribe(result =>{
      if(result.StatusCode == ResponseStatusCodes.SUCCESS){
        this._analyticsService.emitEvent('submit-success', `Got Formulary Info for ${searchedDrug}`);
        this._formularyService.SetFormularyDrugInfo(result);
        this._globalService.SetDrugSearch(false);
      }
      else{
        this._analyticsService.emitEvent('submit-error', `Did not get Formulary Info for ${searchedDrug}`);
        this.drugSearchErrorMessage = "There was a problem getting formulary data, please try again."
        this.showDrugSearchError = true;
        this._globalService.SetDrugSearch(false);
      }
    });
  }

  /**
   * Notifies this.drugSearch.valueChanges event that it was a paste and handles
   * a drug search accordingly. see line 60 - 62
   */
  CheckPasteEvent(){
    this.hasPaste = true;
  }

  /**
   * Defines if the Search button in the view should be disabled or not, returns true if 
   * it should be disabled and false if it is a valid submit.
   * @param drugName 
   * @returns 
   */
  IsSubmitButtonDisabled(drugName: string): boolean {
    let disabled = true;
    if(!drugName) return disabled;
    this.foundDrugList.forEach(drugResponse => {
      if(drugResponse.DrugName == drugName) disabled = false;
    });
    return disabled;
  }

}
