import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from 'src/model/config.model';
import { ConfigData } from 'src/model/response/config-data.model';
import { BaseResponse } from 'src/model/response/base-response.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseStatusCodes } from 'src/model/response/response-status-codes.enum';

declare var ConfigToken: string;

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(private _http: HttpClient) { }

  /**
   * Fetches config from config service as Observable.
   */
  public InitConfig(): Observable<BaseResponse<ConfigData>>{
    return this._http.get<BaseResponse<ConfigData>>(`${environment.configUrl}?configToken=${ConfigToken}&castValue=true`);
  }

  /**
   * Fetches config from config service and returns promise once config is set.
   */
  public InitConfigPromise() {
    return new Promise<void>((resolve, reject) =>{
      this._http.get<BaseResponse<ConfigData>>(`${environment.configUrl}?configToken=${ConfigToken}&castValue=true`).toPromise().then((result: BaseResponse<ConfigData>) =>{
        if(result.StatusCode == ResponseStatusCodes.SUCCESS){
          this.SetConfig(result.Data.Settings);
          resolve();
        }
        else{
          // TODO: write handling errors from the config service
        }
      });
    });
    
  }

  /**
   * Sets config in session storage
   * @param config 
   */
  public SetConfig(config: Config){
    sessionStorage.setItem("config", JSON.stringify(config));
  }

  /**
   * Removes config from session storage
   */
  public RemoveConfig(){
    sessionStorage.removeItem("config");
  }

  /**
   * Gets config from session storage
   */
  public GetConfig(): Config{
    var config = sessionStorage.getItem("config");
    return config !== null ? JSON.parse(config) : new Config();
  }

  /**
   * Sets favicon once application is loaded
   * @param path 
   */
  public SetFavicon(path: string){
    const favicon = document.createElement('link');
    favicon.href = path;
    favicon.rel = 'icon';
    favicon.type = 'image/x-icon';
    document.head.appendChild(favicon);
  }
}
