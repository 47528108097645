import { Pipe, PipeTransform } from '@angular/core';
import { DrugSearchResponse } from 'src/model/response/drug-search-response.class';

@Pipe({
  name: 'filterDrugList'
})
export class FilterDrugListPipe implements PipeTransform {

  /**
   * Pipe that filters drug search results from drop down list in Drug Search Component
   * @param value 
   * @param arg 
   */
  transform(value: Array<DrugSearchResponse>, arg: string): Array<DrugSearchResponse> {
    if(!value || !arg) return value;
    const filterValue = arg.toLowerCase();
    if(value.length == 0)  return value;
    return value.filter((option) => {
      return option.DrugName.toLowerCase().includes(filterValue)
    });
  }

}
