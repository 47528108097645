<div class="main-wrapper">
  <div class="main-container">
    <app-header></app-header>
    <div class="content-wrapper">
      <div class="content-container">
        <app-drug-search></app-drug-search>
        <app-formulary-display *ngIf="!showSearch"></app-formulary-display>
        <div id="disclaimer" class="disclaimer-wrapper">
          <div class="disclaimer-container">
            <p class="copy-write-label">&#169; 2020 Maxor National Pharmacy Services, LLC</p>
            <button mat-button  color="primary" (click)="OpenTermsOfUse()">Terms of Use</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="global-loading-wrapper" *ngIf="globalLoading">
  <div class="global-loading-container">
    <h1 class="global-loading-text">Getting Formulary Data</h1>
    <mat-spinner class="global-loading-spinner"></mat-spinner>
  </div>
</div>

