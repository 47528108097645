import { Component, OnInit, AfterContentInit, ContentChildren, QueryList, ViewChild, Input, AfterViewInit } from '@angular/core';
import { MatHeaderRowDef, MatRowDef, MatColumnDef, MatTable } from '@angular/material/table';
import { DataSource } from '@angular/cdk/table';
import { DrugInfoResponse } from 'src/model/response/drug-info-response.model';

@Component({
  selector: 'formulary-table',
  templateUrl: './formulary-table.component.html',
  styleUrls: ['./formulary-table.component.scss']
})
export class FormularyTableComponent<T> implements AfterContentInit {

  @ContentChildren(MatHeaderRowDef) headerRowDefs!: QueryList<MatHeaderRowDef>;
  @ContentChildren(MatRowDef) rowDefs!: QueryList<MatRowDef<T>>;
  @ContentChildren(MatColumnDef) columnDefs!: QueryList<MatColumnDef>;

  @ViewChild(MatTable, {static: true}) table!: MatTable<T>;

  @Input() columns!: string[];

  @Input() dataSource!: Array<DrugInfoResponse>;

  /**
   * When content is loaded we set passed in data and 
   * set view height based on any column wrapping that may exist.
   */
  ngAfterContentInit() {
    this.columnDefs.forEach(columnDef => this.table.addColumnDef(columnDef));
    this.rowDefs.forEach(rowDef => this.table.addRowDef(rowDef));
    this.headerRowDefs.forEach(headerRowDef => this.table.addHeaderRowDef(headerRowDef));
  }

}
