import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/services/config.service';
import { Config } from 'src/model/config.model';

@Component({
  selector: 'app-terms-of-use-modal',
  templateUrl: './terms-of-use-modal.component.html',
  styleUrls: ['./terms-of-use-modal.component.scss']
})
export class TermsOfUseModalComponent implements OnInit {

  config!: Config;
  clientName!: string;

  constructor(private _configService: ConfigService) { 
    this.config = this._configService.GetConfig();
    this.clientName = this.config.ClientName;
  }

  ngOnInit(): void {
  }

}
