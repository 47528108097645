<div class="formulary-tab-wrapper">
    <div class="formulary-tab-container">
        <mat-tab-group mat-align-tabs="start" (selectedTabChange)="TabChangeEvent($event);">
            <br>
            <div *ngFor="let formularyData of foundFormularyData.Data">
                <div class="formulary-wrapper">
                    <div class="formulary-container">
                        <mat-tab [label]="formularyData.FormularyName">
                            <mat-expansion-panel expanded disabled class="mat-elevation-z0">
                                <mat-expansion-panel-header class="primary-background">
                                    <mat-panel-title>
                                        <span class="bold">Search Results:</span>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <formulary-table [dataSource]="formularyData.PrimaryDrugInfo" [columns]="columnsToDisplay">
                                    <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
                                    <mat-row *matRowDef="let row; columns: columnsToDisplay; when: CheckRowHasData"></mat-row>
                                    <mat-row *matRowDef="let row; columns: columnsToDisplay; when: CheckRowHasNoData"></mat-row>
                                    <!-- Add this to table if we want expanded rows (click)="row.isExpanded = !row.isExpanded" -->
                                    <!-- <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row" [@detailExpand]="row.isExpanded ? 'expanded' : 'collapsed'" ></mat-row> -->
                                </formulary-table>
                            </mat-expansion-panel>
                            <br>
                            <mat-expansion-panel expanded class="mat-elevation-z0" >
                                <mat-expansion-panel-header class="primary-background">
                                    <mat-panel-title>
                                        <span class="bold">Alternatives:</span>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <formulary-table [dataSource]="formularyData.AlternativeDrugInfo" [columns]="columnsToDisplay" *ngIf="formularyData.AlternativeDrugInfo.length > 0">
                                    <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
                                    <mat-row *matRowDef="let row; columns: columnsToDisplay; when: CheckRowHasData"></mat-row>
                                    <mat-row *matRowDef="let row; columns: columnsToDisplayNone; when: CheckRowHasNoData "></mat-row>
                                    <!-- Add this to table if we want expanded rows (click)="row.isExpanded = !row.isExpanded" -->
                                    <!-- <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row" [@detailExpand]="row.isExpanded ? 'expanded' : 'collapsed'" ></mat-row> -->
                                </formulary-table>
                                <div class="no-alternatives-found" *ngIf="formularyData.AlternativeDrugInfo.length == 0">
                                    No alternatives found
                                </div>
                            </mat-expansion-panel>
                            <div class="formulary-date-display">
                                <p>Last Updated {{formularyData.DisplayDate}}</p>
                            </div>
                        </mat-tab>
                    </div>
                </div>
            </div>
        </mat-tab-group>
    </div>
</div>  