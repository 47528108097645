export enum ResponseStatusCodes {
    SUCCESS = "SUCCESS",
    APP_DISABLED = "APP_DISABLED",
    INVALID_CONFIG_TOKEN = "INVALID_CONFIG_TOKEN",
    INVALID_DRUG_NAME = "INVALID_DRUG_NAME",
    INVALID_WEB_REGION_ID = "INVALID_WEB_REGION_ID",
    CONTEXT_DISABLED = "CONTEXT_DISABLED",
    INVALID_CONFIG_SETTING = "INVALID_CONFIG_SETTING",
    INVALID_CONTEXT = "INVALID_CONTEXT",
    NO_CONFIGURATIONS_FOUND = "NO_CONFIGURATIONS_FOUND",
    NO_DRUGS_FOUND = "NO_DRUGS_FOUND",
    NO_FORMULARIES_FOUND = "NO_FORMULARIES_FOUND",
    ERROR = "ERROR"
}
