import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Config } from 'src/model/config.model';
import { ConfigService } from './config.service';

declare var gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  config!: Config;

  constructor(private _router: Router,
              private _configService: ConfigService) { }

  /**
   * Initializes Google Analytics on the head file of the application.
   */
  init() {
    this.ListenForRouteChanges();
    this.config = this._configService.GetConfig();
    try {

      const script1 = document.createElement('script');
      script1.async = true;
      script1.src = 'https://www.googletagmanager.com/gtag/js?id=' + this.config.GoogleAnalyticsKey;

      const script2 = document.createElement('script');
      script2.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${this.config.GoogleAnalyticsKey}');
      `;
      document.head.insertBefore(script2, document.head.firstChild);
      document.head.insertBefore(script1, document.head.firstChild);
    } catch (ex) {
      console.error('Error appending google analytics');
      console.error(ex);
    }
  }

  /**
   * Emits event for Google Analytics
   * @param category 
   * @param action 
   * @param label 
   */
  public emitEvent(category: string, action: string, label: string = "") {
    gtag('event', action, {
      'event_category' : category,
      'event_label' : label
    });
  }

  /**
   * Listens for Route Changes and emits changes to Google Analytics.
   */
  private ListenForRouteChanges() {
    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', this.config.GoogleAnalyticsKey, 
        {
          'page_path': event.urlAfterRedirects,
        });
      }
    });
  }

}
