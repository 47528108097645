import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from 'src/model/config.model';
import { ConfigService } from './config.service';
import { DrugSearchRequest } from 'src/model/request/drug-search-request.model';
import { BaseResponse } from 'src/model/response/base-response.model';
import { DrugSearchResponse } from 'src/model/response/drug-search-response.class';
import { BehaviorSubject, Observable } from 'rxjs';
import { StorageIdentifiers } from '../model/storage-identifiers.enum';
import { FormularySearchRequest } from 'src/model/request/formulary-search-request.model';
import { FormularySearchResponse } from 'src/model/response/formulary-search-response.model';

@Injectable({
  providedIn: 'root'
})
export class FormularyLookupService {

  public formularyDrugInfoElement$ = new BehaviorSubject<BaseResponse<Array<FormularySearchResponse>>>(new BaseResponse<Array<FormularySearchResponse>>());
  public formularyDrugInfoItem$ = this.formularyDrugInfoElement$.asObservable();

  config!: Config;

  constructor(private _http: HttpClient,
              private _configService: ConfigService) {
    this.config = this._configService.GetConfig();
  }

  /**
   * Searches drug and returns it as an observable.
   */
  SearchDrug(request: DrugSearchRequest): Observable<BaseResponse<Array<DrugSearchResponse>>> {
    return this._http.post<BaseResponse<Array<DrugSearchResponse>>>(`${this.config.BaseUrl}/Drug/SearchDrug`, request);
  }

  /**
   * Gets formulary results from a drug name
   * @param request 
   */
  GetFormularyDrugInfo(request: FormularySearchRequest): Observable<BaseResponse<Array<FormularySearchResponse>>> {
    return this._http.post<BaseResponse<Array<FormularySearchResponse>>>(`${this.config.BaseUrl}/Formulary/GetFormularyDrugInfo`, request);
  }

  /**
   * Sets formulary drug info in local storage to be used for later.
   * @param payload 
   */
  SetFormularyDrugInfo(payload: BaseResponse<Array<FormularySearchResponse>>){
    this.formularyDrugInfoElement$.next(payload);
    localStorage.setItem(StorageIdentifiers.FormularyResult, JSON.stringify(payload));
  }

}
