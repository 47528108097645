import { ResponseStatusCodes } from './response-status-codes.enum';

export class BaseResponse<T> {
    App!: string;
    Method!: string;
    StatusCode!: ResponseStatusCodes
    Status!: string;
    MessageCode!: string;
    Message!: string;
    ExceptionInfo!: string;
    Data!: T;
}
