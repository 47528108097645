import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {

  constructor(protected _sanitizer: DomSanitizer){}

  /**
   * Pipe that Allows HTML to be injected into particular informational displays
   * @param value 
   */
  transform(value: any): SafeHtml {
      return this._sanitizer.bypassSecurityTrustHtml(value);
  }
}
