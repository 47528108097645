import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { GlobalService } from 'src/services/global.service';

@Injectable()
export class MaxorHttpInterceptor implements HttpInterceptor {

  constructor(private _router: Router,
              private _globalService: GlobalService ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    return next.handle(request).pipe( tap(() => {},
      (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status == 401) {
          this._router.navigateByUrl("/home");
         return;
        }
        if(err.status == 500){
          this._globalService.SetGlobalLoading(false);
        }
      }
    }));
  }
}
